.formItem {
  margin-bottom: 10px;
}

.loginFormForgot {
  float: right;
}

.loginFormButton {
  width: 100%;
}

.loginInputIcon {
  color: rgba(0,0,0,.25)
}

.orDiv {
  margin-bottom: 10px;
  width: 65px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.signInHeader {
  text-align:center;
  width:100%;
}
