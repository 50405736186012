.mainLayout {
    min-height: 100vh;
}

.header {
    padding: 0 10px;
}

.uiWrapper {
    padding: 10px 10px;
}

.uiWrapper section {
    padding: 24px 0;
    background: #fff;
    min-height: calc(100vh - 120px);
}

.appContent {
    padding: 0 24px;
    //height: calc(100vh - 145px);
}

.mainLayout .logo {
    font-size: 35px;
    font-weight: bolder;
    //width: 200px;
    color: #f7f7f7;
    display: inline-block;
}

.mainLayout .confIcon {
    font-weight: normal;
    //font-size: 33px;
}

.mainLayout .avatarWrapper {
    position: absolute;
    top: 0;
    right: 50px;
}

.footer {
    padding: 0 10px 15px 10px;
    font-size: 12px;
}
