//.dynamicDeleteButton {
//  cursor: pointer;
//  position: relative;
//  top: 4px;
//  font-size: 24px;
//  color: #999;
//  transition: all .3s;
//}
//.dynamicDeleteButton:hover {
//  color: #777;
//}
//.dynamicDeleteButton[disabled] {
//  cursor: not-allowed;
//  opacity: 0.5;
//}
//
//input:-webkit-autofill {
//  -webkit-box-shadow: 0 0 0 30px white inset;
//}
//
//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus,
//input:-webkit-autofill:active {
//  transition: background-color 5000s ease-in-out 0s;
//}

.authorsGroup {
  width: 100%
}
